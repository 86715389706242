.home-banner {
    overflow: hidden;
    background-position: top;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    height: 480px;
    width: 100%;

    &__overlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(90deg, rgba(0, 163, 147, 0.3), rgba(0, 163, 147, 0.1));
    }
    &__intro {
        position: absolute;
        top: 46%;
        left: 40%;
        transform: translate(-50%, -50%);
        width: 60%;

        &__text {
            font-size: 38px;
            line-height: 48px;
            color: $white;
            text-shadow: $primary-color 1px 1px;

            @media (min-width: 768px) {
                font-size: 42px;
            }
        }
    }
}

.video {
    position: relative;
    background-color: black;

    &__container {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }

    &__thumbnail {
        width: 100%;
        height: 100%;

        &__image {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    &__button {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        text-align: center;
    }

    &__text {
        font-family: $font-family-impact;
        font-size: 1.7rem;
        color: $brand-color;
        text-shadow: $primary-color 1px 1px;

        @media (min-width: 768px) {
            font-size: 4rem;
        }
    }
}

.social-links {
    ul {
        padding: 0;
        margin: 0;

        li {
            display: inline-block;
            text-align: center;
            margin-right: 8px;

            &:last-child {
                margin-right: 0;
            }

            a {
                display: inline-block;
                width: 36px;
                height: 36px;
                line-height: 36px;
                color: $border-color;
                font-size: 12px;
                border: 1px solid rgba(255, 255, 255, 0.08);
                -webkit-transition: all 300ms linear;
                transition: all 300ms linear;

                &:hover {
                    border-radius: 100%;
                }
            }
        }
    }
}

.navbar-brand {
    font-family: $font-family-impact;
    color: $brand-color !important;

    h1 {
        margin-bottom: 0;
    }
}

.navbar-nav {
    .nav-link {
        color: white;

        &:focus,
        &:hover,
        &.active {
            color: $brand-color;
        }
    }
}
