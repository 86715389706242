:root {
    --ck-z-default: 100;
    --ck-z-modal: calc( var(--ck-z-default) + 999 );
}
body {
    background-color: $bg-secondary;
    color: $white;
}
a {
    outline: 0;
    color: $primary-color;
}
a:hover {
    outline: 0;
    color: $primary-color;
    text-decoration: none;
}

.navbar {
    .list-group {
        .list-group-item {
            text-align: center;
            padding: 0;
            margin-left: -12px;
            margin-top: -6px;

            i {
                font-size: 24px;
            }
            span {
                display: none;
            }
        }
    }
}

.left-sidebar {
    min-height: 80vh;

    .list-group {
        .list-group-item {
            text-align: center;

            i {
                font-size: 24px;
            }
            span {
                display: block;
                text-transform: uppercase;
                font-size: 8px;
            }
        }
    }
}

.main-content {
    background-color: $bg-primary;
    min-height: 80vh;
}

.table-content {
    min-height: 40vh;
}

.list-group {
    border-radius: 0;

    .list-group-item {
        border-top: 0;
        border-bottom: 0;
        border-right: 0;
        border-left: 6px solid $bg-secondary;
        background-color: $bg-secondary;
        color: $white;
        padding: 0.8rem;

        &:hover,
        &.active {
            background-color: $bg-primary;
            color: $brand-color;
            border-left-color: $brand-color;
        }
    }
}

.user-nav-link {
    img {
        float: left;
        height: 36px;
        width: 36px;
        line-height: 36px;
        border: 1px solid $dark;
        border-radius: 50%;
        overflow: hidden;
        vertical-align: middle;
        text-align: center;
    }

    .user-nav-info {
        display: inline-block;
        line-height: 18px;
    }
}

.ck-editor__editable {
    min-height: 300px;
}

.post-single-view {
    &__content {
        &--timeline {
            list-style: none;
            text-align: center;
            padding: 0;

            &__item {
                border-top: 1px dashed gray;
                display: inline-block;
                padding-right: 40%;
                margin-top: -1rem;
                font-size: 12px;

                &:last-child {
                    padding-right: 0;
                }
            }
        }
    }
}

.position-overlay-right {
    position: absolute;
    top: 0;
    right: 0;
}

.b-toast {
    background-color: #fff;
}

.breadcrumb {
    background-color: transparent;
    padding: 0;

    a {
        text-decoration: underline;

        &:hover {
            text-decoration: none;
        }
    }
}

.table-thin th,
.table-thin td {
    padding: 4px;
}

.page-footer {
    font-size: 12px;
}

.w-10 {
    width: 12%;
}

.total-card {
    margin-top: 4px;
    padding: 2px;
    background-color: $bg-input;
    color: $text-muted;
}

.remove-arrow {
    .dropdown-toggle::after {
        content: none;
    }
}

.filter-height {
    height: 180px;
    overflow-y: scroll;
}

.card-footer {
    background-color: transparent;
    border-color: $border-color;
}

.card-img {
    overflow: hidden;
    border-radius: 16px;
}

.btn {
    font-weight: 700;
    border-radius: 12px;
}

hr {
    border-color: $border-color;
}

.dropdown-menu {
    background-color: $bg-secondary;

    .dropdown-item {
        color: $white;

        &:hover {
            background-color: $bg-primary;
        }
    }

    .dropdown-divider {
        border-color: $border-color;
    }
}

.custom-file-input,
.custom-select,
.form-control {
    border-radius: 8px;
    background-color: $bg-input;
    color: $white;
    border-color: $border-color;

    &:focus {
        border-color: $border-color;
        background-color: $bg-input;
        color: $white;
    }
}

.jumbotron,
.modal-content {
    background-color: $bg-secondary;
    border-radius: 16px;
    border: 1px;
}

.close {
    color: $white;
}

.card {
    border-radius: 16px;
    border-color: $border-color;
    background-color: $bg-primary;
    overflow: hidden;
}

.sr-only,
.table {
    color: $gray-light;
}

.table-hover tbody tr:hover td,
.table-hover tbody tr:hover th {
    color: $white;
}

.b-calendar-grid-help {
    background-color: $bg-primary;
}

.table-warning {
    color: $bg-secondary;

    &:hover td {
        color: $bg-secondary !important;
    }
}


.pagination {
    border-radius: 8px;

    .page-link {
        border-color: $border-color !important;
    }

    .page-item.disabled {
        .page-link {
            background-color: $bg-input;
        }
    }
}
