.no-timeline:before,
.no-timeline:after {
    border: 0 !important;
    margin-top: 0 !important;
}
.timeline {
    margin-left: 16px;
    list-style: none;
    position: relative;
}
.timeline:before {
    position: absolute;
    top: 0;
    bottom: 0;
    left: -6px;
    content: " ";
    border-right: 2px solid $border-color;
}
.timeline > li {
    position: relative;
    margin-left: -20px;
    margin-top: 40px;
}
.timeline > li.view-more-link:before,
.timeline > li.view-more-link:after {
    border: 0;
}
.timeline > li:before,
.timeline > li:after {
    position: absolute;
    top: 15px;
    right: auto;
    display: none;
    border-top: 15px solid transparent;
    border-left: 0;
    border-right: 15px solid $border-color;
    border-bottom: 15px solid transparent;
    content: " ";
    left: -15px;
}
.timeline > li > .timeline__date {
    position: absolute;
    background-color: var(--border-color);
    padding: 2px 8px;
    border-radius: 0 4px 0 0;
    top: -22px;
    left: -26px;
    font-size: 12px;
    white-space: nowrap;
    color: $white;
    text-transform: uppercase;
    z-index: 99;
}
.timeline > li > .timeline__badge {
    position: absolute;
    left: -40px;
    top: 14px;
    background-color: $border-color;
    color: $brand-color;
    padding: 8px;
    width: 32px;
    height: 32px;
    font-size: 12px;
    line-height: 16px;
    text-align: center;
    z-index: 99;
    border-radius: 50%;
}

@media (min-width: 576px) {
    .timeline > li > .timeline__badge {
        left: -56px;
    }
    .timeline > li {
        margin-left: 0;
    }
}

@media (min-width: 768px) {
    .timeline {
        margin-left: 96px;
    }
    .timeline:before {
        left: 0;
    }
    .timeline > li {
        margin-top: 30px;
    }
    .timeline > li:before,
    .timeline > li:after {
        display: inline-block;
    }
    .timeline > li > .timeline__date {
        background-color: transparent;
        padding: 0;
        left: -148px;
        top: 12px;
        width: 80px;
        white-space: break-spaces;
        text-align: right;
    }
    .timeline > li > .timeline__date span {
        display: block;
    }
}

.post-page__date {
    list-style: none;
    padding: 0;
    color: $white;
}
.post-page__date__item {
    position: relative;
    border-top: 3px dashed $border-color;
    display: inline-block;
    padding-right: 36%;
    margin-top: -1rem;
    font-size: 12px;
    text-transform: uppercase;
}
.post-page__date__item__icon {
    color: $border-color
}
.post-page__date__item--last {
    padding-right: 0;
}
.post-page__date span {
    background-color: $white;
    position: absolute;
    top: -10px;
    left: 0;
    width: 96px;
}
.post-page__image {
    position: relative;
    background-color: $border-color;
    border-radius: 4px;
    padding: 8px;
    text-align: center;
}
.post-page__image__object {
    border-radius: 4px;
    position: relative;
}
.post-page__image__icon {
    position: absolute;
    right: 16px;
    top: 8px;
    color: $gray-light;
}
