// Fonts
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Island+Moments&display=swap');

// Variables
@import 'variables';

// Bootstrap
@import '~bootstrap';
@import '~bootstrap-vue';

// Custom styles
@import "custom";
@import "utility";
@import "event";
@import "store";
@import "home";
