// Typography
$font-family-sans-serif: 'Montserrat', sans-serif;
$font-family-impact: 'Island Moments', cursive;
$font-size-base: 0.9rem;
$line-height-base: 1.6;

// Colors
$blue: #007bff;
$indigo: #6574cd;
$purple: #9561e2;
$pink: #f66d9b;
$red: #e3342f;
$orange: #f6993f;
$yellow: #ffed4a;
$green: #38c172;
$teal: #008080;
$cyan: #6cb2eb;

$brand-color: #00A393; //rgb(0, 163, 147)
$border-color: rgba(255, 255, 255, 0.08); //sand
$primary-color: #00A393;

$gray-light: #f1f1f1;
$bg-primary: #292A2D;
$bg-secondary: #202123;
$bg-input: #404144;
$theme-colors: (
    "primary": #00A393,
);
