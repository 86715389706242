.cart-update-input {
    width: 80px;
    display: inline-block;
}

.on-sale-badge {
    position: absolute;
    top: 16px;
    right: 16px;
}

.empty-box {
    height: 40px;
    width: auto;
}

.product-card {
    overflow: hidden;
    width: 100%;

    a {
        &:hover {
            text-decoration: none;
        }
    }

    &__body {
        display: flex;
        flex-direction: column;

        &__top {
            flex: 1;
        }

        &__bottom {
            display: flex;
            justify-content: flex-end;
        }
    }

    &__image {
        width: 100%;
        height: 280px;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center top;
    }

    &__footer {
        display: flex;
        justify-content: flex-end;

        &__section {
            flex: fit-content;
        }
    }
}

.product-page {
    &__sticky-offset {
        top: 20px;
    }

    &__variant {
        border: 2px solid $border-color;
        border-radius: 8px;
        margin-right: 8px;
        margin-bottom: 8px;
        display: inline-block;
        padding: 6px 12px;
        cursor: pointer;
        text-align: center;

        &:hover {
            border-color: $primary-color;
        }

        &--active {
            border-color: $primary-color;
        }
    }
}

.color-pad {
    border: 1px solid $border-color;
    border-radius: 8px;
    width: 48px;
    height: 16px;
}
